<template>
  <div class="flex flex-col">
    <div v-for="(group, index) in items" :key="index">
      <div v-for="item in group">
        <component
          :is="!!item.href ? 'a' : 'button'"
          :href="!item.disabled ? href : undefined"
          @click="onClick($event, item)"
          class="flex items-center justify-between w-full gap-1.5 px-1.5 py-1.5 text-left text-sm text-cool-700 dark:text-white dark:hover:bg-[#2B3236]"
          :class="{
            'cursor-not-allowed opacity-50': item.disabled,
          }"
        >
          <span v-html="item.label" />
          <font-awesome-icon
            v-if="item.icon"
            :icon="item.icon"
            class="flex-shrink-0 w-5 h-5"
          />
        </component>
      </div>
      <UDivider v-if="index < items.length - 1" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    items: Array,
  })

  const onClick = (e, item) => {
    if (item.disabled) {
      return
    }

    if (item.click) {
      item.click(e)
    }

    if (item.href) {
      navigateTo(item.href)
    }
  }
</script>
